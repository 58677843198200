@import url("./app/styles/sidebar.css");
@import url("../node_modules/ol/ol.css");
@import url("../node_modules/fontawesome-free/css/all.css");
@import url("../node_modules/open-props/open-props.min.css");
@import url("./app//styles/variables.css");
@import url("./app/styles/map.css");
@import url("./app/styles/snackbar.css");
@import url("./app/styles/buttons.css");
@import url("./app/styles/login.css");
@import url("./app//styles/utility.css");


html, body {
  margin: 0;
  height: 100%;
  height: 100vh;
}

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: var(--font-weight-5);
  background-color: var(--gray-2);
}


.main-container{
  display: grid;
  grid-template-areas:
  "nav"
  "main"
  ;

  grid-template-rows: 4em 1fr;
}

app-main > header{
  width: 100%;
  height: 4em;
  background-color: #363537;
  color: white;
  display: grid;
  align-items: center;
  padding-inline: 1em;
  grid-area:nav;
}

app-nav > nav {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 100px 1fr 100px;
}

app-nav > nav > * {
  align-items: center;
  display: inherit;
}

app-main > main{
  grid-area: main;
  height: 100%;
}

app-main > footer{
  width: 100%;
  height: 4em;
  background-color: var(--gray-9);
  color: white;
  display: grid;
  align-items: center;
  padding-inline: 1em;
  grid-area: footer;
}

mat-sidenav-container {
  height: 100%;

}

mat-sidenav
{
  width:10%;
  background-color: white !important;
}

mat-sidenav-content{
  padding: 2em;
  background-color: rgb(247,251,250)!important;
  color: black;
  display: grid;
  gap: 2em;
  border-color: black!important;
  height: 100%;
}
.form-label{
  display: block;
}
.form-label > *{
  display: block;
}

app-root {

  height: 100%;
  display: grid;

}


.sidenav{
  list-style: none;
  margin: 0;
  padding:0;
  display: grid;
  gap: 1em;
  align-items: center;

}

.sidenav > li > a {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 50px 1fr;
  align-items: center;
  font-size: 1.1em;
  cursor: pointer;
  color: var(--gray-8);
}

.sidenav .fa {
  padding-top: 0;
}

.admin-page{
  display: grid;
  gap:1em;
  height: 100%;
  grid-template-rows: 50px 100px 1fr;
}

.admin-page_header{
  display: grid;
  grid-auto-flow: column;
  padding-inline: 1em;
  grid-auto-columns: 1fr 150px;
}


.user-management_filter{
    background-color: white;
    box-shadow: var(--shadow-2);
    padding: 1em;
    border-radius: var(--radius-2);
}

.user-management_container{
    display: grid;
    grid-auto-rows: 1fr 100px;
    background-color: white;
    box-shadow: var(--shadow-2);
    padding: 1em;
    border-radius: var(--radius-2);
}

.user-management_actions{
  align-self: center;
  padding: 1em;
  background-color: var(--gray-2);
  border-radius: var(--radius-2);
}

.user-management_table-container{
  display: grid;
  grid-template-rows: 1fr 50px;

}
.user-management_table{
  width: 100%;
  max-height: 100%;
  background-color: white!important;
  color: black!important;
}


.mat-header-cell{
  color: black!important;
  text-align: center;
}

.mat-cell{
  color:black!important;
  text-align: center;
}

mat-row, mat-header-row, mat-footer-row, th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
  border-bottom-color: rgba(63, 63, 63, 0.12)!important;
}


.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  max-height: 400px;
}

.table-responsive .mat-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  display: table;
  border-collapse: collapse;
  margin: 0px;
}

.table-responsive .mat-row {
  display: table-row;
}
.table-responsive .mat-header-row {
  display: table-row;
  position: sticky;
  top: 0;
  background-color: rgb(224,241,236);
  margin-bottom: 1em;
}


table th:first-child{
  border-radius:var(--radius-2) 0 0 var(--radius-2);
}

table th:last-child{
  border-radius:0 var(--radius-2) var(--radius-2) 0;
}

.overlay{
  width: 400px;
  background-color: white;
  position: relative;
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
  border: 1px solid var(--gray-5)

}

.overlay > header {
  border-bottom: 1px solid var(--gray-5);
  background-color: var(--gray-3);
  padding:1em;
  text-align: center;
  border-radius: var(--radius-2) var(--radius-2) 0 0 ;
}

.overlay > main {
  padding:1em;
  border-radius: inherit;

}

.overlay > * {
  background-color: inherit;
}


app-attribute-overlay{
  position: relative;
}
app-attribute-overlay::before{
  content: '';
  position: absolute;
  left: 42%;
  bottom: 100%;
  width: 0;
  height: 0;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 20px solid white;
  clear: both;
}


.table-responsive .mat-header-cell {
  word-wrap: initial;
  display: table-cell;
  padding: 5px 5px;
  margin-inline-end: 1em;
  line-break: unset;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle;
  text-align: center;
}
.table-responsive .mat-cell {
  word-wrap: initial;
      display: table-cell;
      padding: 0px 5px;
      line-break: unset;
      width: auto;
      white-space: nowrap;
      overflow: hidden;
      vertical-align: middle;
      text-align: center;
}

.table-responsive .mat-cell.grid-column {

  display: grid;
  grid-auto-flow: column;
  gap: 1em;
  padding-block: 0.5em;
  border-bottom: 1px solid rgba(63, 63, 63, 0.12);
}


.mat-paginator{
  border-radius: var(--radius-2);
  background-color: white!important;
  color: black!important;
}


.mat-menu-trigger{
  cursor: pointer;
  text-align: center;
}



.sub-nav{
  display: grid;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 1.25em;
}

.sub-nav_item{
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 20px 1fr;
  gap: 1em;
  text-decoration: none;
  padding: 0.5em;
  margin-inline-start: 1px;
  color: black;
}

.sub-nav_item:hover{
  background-color: var(--gray-1);
  color: var(--green-7);
}

.active-link{
  color: var(--green-7);
  border-left: 3px solid var(--green-7);
}

.main-pane{
  display: grid;
  gap: 1em;
}

.main-pane > header {
  display: flex;
justify-content: space-between;
}


.card{
  padding: 1em;
  border: 1px solid var(--gray-4);
  border-radius: var(--radius-2);
  background-color: white;
  box-shadow: var(--shadow-2);
}


.upload-form{
  border: 3px dashed var(--gray-3);
  border-radius: var(--radius-2);
  padding: 1em;
  gap:0.5em;
  display: grid;
}


.upload-form  i {

  color: var(--light-blue);
}

.mat-dialog-actions > footer {
  display: grid;
  grid-auto-flow: column;
  gap: 0.5em;
}


.fa > span {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-weight: var(--font-weight-5);
}


.mat-card:not([class*=mat-elevation-z]).card-hovered {
    box-shadow: var(--shadow-2)
}

.mat-card:not([class*=mat-elevation-z]){
  border: 1px solid rgba(0,0,0,0.1);
}
mat-card-subtitle{
    margin-bottom: 0!important;
}


.test-system {
  padding: 1em;
  position: absolute;
  top: 1em;
  border: 1px solid red;
  color: red;
  left: 1em;
}

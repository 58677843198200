.sidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  z-index: 2000;

  box-sizing: content-box;
}
.sidebar.collapsed {
  width: 40px;
}

.sidebar-left {
  left: 0;
}

.sidebar-right {
  right: 0;
}

.sidebar-tabs {
  top: 0;
  bottom: 0;
  height: 100%;
  background-color: rgba(0, 60, 136, 0.5);
}
.sidebar-left .sidebar-tabs {
  left: 0;
}
.sidebar-right .sidebar-tabs {
  right: 0;
}
.sidebar-tabs,
.sidebar-tabs > ul {
  position: absolute;
  width: 40px;
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.sidebar-tabs > li,
.sidebar-tabs > ul > li {
  width: 100%;
  height: 40px;
  color: #fff;
  font-size: 12pt;
  overflow: hidden;
  transition: all 80ms;
}
.sidebar-tabs > li:hover,
.sidebar-tabs > ul > li:hover {
  color: #fff;
  background-color: rgba(0, 60, 136, 0.6);
}
.sidebar-tabs > li.active,
.sidebar-tabs > ul > li.active {
  color: #fff;
  background-color: #0074d9;
}
.sidebar-tabs > li.disabled,
.sidebar-tabs > ul > li.disabled {
  color: rgba(255, 255, 255, 0.4);
}
.sidebar-tabs > li.disabled:hover,
.sidebar-tabs > ul > li.disabled:hover {
  background: transparent;
}
.sidebar-tabs > li.disabled > a,
.sidebar-tabs > ul > li.disabled > a {
  cursor: default;
}
.sidebar-tabs > li > a,
.sidebar-tabs > ul > li > a {
  display: block;
  width: 100%;
  height: 100%;
  line-height: 40px;
  color: inherit;
  text-decoration: none;
  text-align: center;
}
.sidebar-tabs > ul + ul {
  bottom: 0;
}

.sidebar-content {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.95);
  overflow-x: hidden;
  overflow-y: auto;
}
.sidebar-left .sidebar-content {
  left: 40px;
  right: 0;
}
.sidebar-right .sidebar-content {
  left: 0;
  right: 40px;
}
.sidebar.collapsed > .sidebar-content {
  overflow-y: hidden;
}

.sidebar-pane {
  display: none;
  left: 0;
  right: 0;
  box-sizing: border-box;
}
.sidebar-pane.active{
  display:grid;
  grid-template-areas:
  "header"
  "main";
  grid-auto-rows: 40px 1fr;
  height: 100%;
  padding-block-end: 1em;
}

.sidebar-pane > header {
  height: 40px;
  padding: 0 20px;
  line-height: 40px;
  font-size: 14.4pt;
  color: #fff;
  background-color: #0074d9;
}

.sidebar-pane > header > * {
padding: 0;
margin: 0;
font-weight: 500;
}
.sidebar-right .sidebar-header {
  padding-left: 40px;
}

.sidebar-pane > main {
  padding: 1em;
}

.sidebar-pane > footer {
  border-top: 1px solid var(--gray-4, #fffeff);
  padding: 1em;
  display: grid;
  align-items: center;
}


.sidebar-close {
  position: absolute;
  top: 0;
  width: 40px;
  height: 40px;
  text-align: center;
  cursor: pointer;
}
.sidebar-left .sidebar-close {
  right: 0;
}
.sidebar-right .sidebar-close {
  left: 0;
}

.sidebar {
  background-color: rgba(255, 255, 255, 0.4);
}

.sidebar-left {
  border-right: 3px solid transparent;
}

.sidebar-right {
  border-left: 3px solid transparent;
}

.sidebar-tabs {
  overflow: hidden;
}

.sidebar-left ~ .sidebar-map .ol-zoom,
.sidebar-left ~ .sidebar-map .ol-scale-line {
  margin-left: 46px;
}

.sidebar-right ~ .sidebar-map .ol-rotate,
.sidebar-right ~ .sidebar-map .ol-attribution,
.sidebar-right ~ .sidebar-map .ol-full-screen {
  margin-right: 46px;
}

@media (min-width: 768px) {
  .sidebar-right {
    right: 6px;
  }
  .sidebar-left {
    left: 6px;
  }
  .sidebar {
    top: 6px;
    bottom: 6px;
    transition: width 500ms;
    border: 3px solid transparent;
    border-radius: 4px;
  }

  .sidebar-right.collapsed ~ .sidebar-map .ol-rotate,
  .sidebar-right.collapsed ~ .sidebar-map .ol-attribution,
  .sidebar-right.collapsed ~ .sidebar-map .ol-full-screen {
    margin-right: 52px;
  }

  .sidebar-tabs {
    border-radius: 2px 0 0 2px;
  }
  .collapsed .sidebar-tabs {
    border-radius: 2px;
  }

  .sidebar-content {
    border-radius: 0 2px 2px 0;
  }

  .sidebar-left ~ .sidebar-map .ol-zoom,
  .sidebar-left ~ .sidebar-map .ol-scale-line {
    transition: margin-left 500ms;
  }

  .sidebar-left.collapsed ~ .sidebar-map .ol-zoom,
  .sidebar-left.collapsed ~ .sidebar-map .ol-scale-line {
    margin-left: 52px;
  }

  .sidebar-right ~ .sidebar-map .ol-rotate,
  .sidebar-right ~ .sidebar-map .ol-attribution,
  .sidebar-right ~ .sidebar-map .ol-full-screen {
    transition: margin-right 500ms;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .sidebar-pane {
    min-width: 265px;
  }

  .sidebar-right ~ .sidebar-map .ol-rotate,
  .sidebar-right ~ .sidebar-map .ol-attribution,
  .sidebar-right ~ .sidebar-map .ol-full-screen {
    margin-right: 317px;
  }

  .sidebar-left ~ .sidebar-map .ol-zoom,
  .sidebar-left ~ .sidebar-map .ol-scale-line {
    margin-left: 317px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .sidebar {
    width: 390px;
  }

  .sidebar-right ~ .sidebar-map .ol-rotate,
  .sidebar-right ~ .sidebar-map .ol-attribution,
  .sidebar-right ~ .sidebar-map .ol-full-screen {
    margin-right: 402px;
  }

  .sidebar-pane {
    min-width: 350px;
  }

  .sidebar-left ~ .sidebar-map .ol-zoom,
  .sidebar-left ~ .sidebar-map .ol-scale-line {
    margin-left: 402px;
  }
}
@media (min-width: 1200px) {
  .sidebar {
    width: 460px;
  }

  .sidebar-right ~ .sidebar-map .ol-rotate,
  .sidebar-right ~ .sidebar-map .ol-attribution,
  .sidebar-right ~ .sidebar-map .ol-full-screen {
    margin-right: 472px;
  }

  .sidebar-pane {
    min-width: 420px;
  }

  .sidebar-left ~ .sidebar-map .ol-zoom,
  .sidebar-left ~ .sidebar-map .ol-scale-line {
    margin-left: 472px;
  }
}

.error-snackbar{
  background-color: var(--red-5);
  border-color: var(--red-8);
  border-width: 1px;
  border-style: solid;
  color: white;
}

.success-snackbar{
  background-color: var(--green-5) !important;
  border-color: var(--green-8);
  border-width: 1px;
  border-style: solid;
  color: white;
  text-align: center;
}


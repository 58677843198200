
.grid{
  display: grid;
}

.grid--column{
  grid-auto-flow: column;
}

.grid--space_between{
  justify-content: space-between;
}

.center{
  place-content: center center;
}

.center--inline{
  justify-content: center;
}

.center--block{
  align-items: center;
}

.center--text{
  text-align: center;
}

.grid--gap{
  gap: 1em;
}

.grid--gap-sm{
  gap:0.5em
}

.overflow-y--scroll{
  overflow-y: scroll;
}


.flex{
  display: flex;
}

.flex--column{
  flex-direction: column;
}

.login{
  min-width: 400px;
  place-self: center center;
  background-color: white;
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-2);
  display: grid;
  gap:1em;
  padding: 1em;
  text-align: center;
}

.login > header > h1 {
  margin: 0;
}

.login_form{
  display: grid;
  gap:0.5em;
}

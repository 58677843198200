.button {
  border-color: var(--button-border-color);
  border-width: 1px;
  border-style: solid;
  background-color: var(--button-bg);
  color: var(--button-txt-color);
  border-radius: 0.25em;
  align-self: center;
  cursor: pointer;
  font-size: 1rem;
  padding-inline: 1rem;
  padding-block:0.5rem;
  transition: background-color 100ms ease-in;
  display: inline;
  box-shadow: var(--shadow-1);
}


.button--xs {
font-size: 0.25rem;
}

.button--sm {
  font-size: 0.5rem;
}

.button--md {
  font-size: 0.75rem;
}

.button--xl {
  font-size: 1.25rem;
}

.button--xxl {
  font-size: 1.5rem;
}

.button--full_width{
  width: 100%;
}

.button--brand {
  --button-bg: var(--brand-color);
  --button-border-color: white;
  --button-hover-bg: hsl(148, 47%, 40%);
}

.button--purple-fill {
  --button-bg: var(--light-purple);
  --button-border-color: var(--dark-purple);
  --button-hover-bg: var(--dark-purple);
}

.button--purple-light-fill {
  --button-bg: var(--white);
  --button-border-color: var(--dark-purple);
  --button-txt-color: var(--dark-purple);

  --button-hover-bg: var(--dark-purple);
}

.button--green {
  --button-bg: var(--white);
  --button-border-color: var(--light-green);
  --button-txt-color: var(--light-green);

  --button-hover-bg: var(--light-green);
}

.button--orange {
  --button-bg: var(--light-orange);
  --button-border-color: var(--dark-orange);
  --button-txt-color: var(--white);


  --button-hover-bg: var(--dark-orange);
}

.button--danger{
  --button-bg: var(--red-7);
  --button-border-color: var(--red-7);
  --button-hover-border-color: var(--red-9);
  --button-hover-bg: var(--red-9);
}

.button--blue {

  --button-bg: var(--light-blue);
  --button-border-color: var(--dark-blue);
  --button-txt-color: var(--white);
  --button-hover-bg: var(--dark-blue);

}

.button--blue-outline {
  --button-bg: var(--white);
  --button-border-color: var(--light-blue);
  --button-txt-color: var(--light-blue);

  --button-hover-bg: var(--dark-blue);

}

.button--black {
  --button-txt-color: var(--white);
  --button-bg: var(--dark-gray);
  --button-border-color: var(--light-gray);

  --button-hover-bg: var(--dark-green);
}

.button--black-outline {
  --button-txt-color: black;
  --button-bg: var(--white);
  --button-border-color: var(--dark-gray);

  --button-hover-txt-color: var(--white);
  --button-hover-bg: var(--dark-gray);
  --button-hover-border-color: var(--dark-gray);
}

.button:disabled
{
  --button-bg: var(--button-disabled-color);
  --button-hover-bg:var(--button-disabled-color);
  --button-border-color:var(--button-disabled-color);
  pointer-events: none;
  cursor: unset;
}

a[type=button]{
  text-decoration: none;
}

[type=button].disabled,
[type=button]:disabled{
  color:  var(--button-disabled-color);
  border-color:var(--button-disabled-color);
  pointer-events: none;
  cursor: unset;
}



.button:active,
.button:hover {

  --button-bg: var(--button-hover-bg);
  --button-txt-color: var(--button-hover-txt-color);
  --button-border-color: var(--button-hover-border-color);

}

/* Just for example */
html {
  background-color: #f1f2f3;
}

/* -----------------------------
Switch */

.uk-switch {
  position: relative;
  display: inline-block;
  height: 34px;
  width: 60px;
}

/* Hide default HTML checkbox */
.uk-switch input {
  display:none;
}
/* Slider */
.uk-switch-slider {
  background-color: rgba(0,0,0,0.22);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-radius: 500px;
  bottom: 0;
  cursor: pointer;
  transition-property: background-color;
	transition-duration: .2s;
  box-shadow: inset 0 0 2px rgba(0,0,0,0.07);
}
/* Switch pointer */
.uk-switch-slider:before {
  content: '';
  background-color: #fff;
  position: absolute;
  width: 30px;
  height: 30px;
  left: 2px;
  bottom: 2px;
  border-radius: 50%;
  transition-property: transform, box-shadow;
	transition-duration: .2s;
}
/* Slider active color */
input:checked + .uk-switch-slider {
  background-color: #39f !important;
}
/* Pointer active animation */
input:checked + .uk-switch-slider:before {
  transform: translateX(26px);
}

/* Modifiers */
.uk-switch-slider.uk-switch-on-off {
  background-color: #f0506e;
}
input:checked + .uk-switch-slider.uk-switch-on-off {
  background-color: #32d296 !important;
}

/* Style Modifier */
.uk-switch-slider.uk-switch-big:before {
  transform: scale(1.2);
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
.uk-switch-slider.uk-switch-small:before {
  box-shadow: 0 0 6px rgba(0,0,0,0.22);
}
input:checked + .uk-switch-slider.uk-switch-big:before {
  transform: translateX(26px) scale(1.2);
}

/* Inverse Modifier - affects only default */
.uk-light .uk-switch-slider:not(.uk-switch-on-off) {
  background-color: rgba(255,255,255,0.22);
}

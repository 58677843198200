#map{
  height: 100%;
  height: 100vh;

  width: 100%;
  width: 100vw;
}


.animate_visible {
  opacity: 1;
  transition: all 1s;
}

.hidden{
  display: none;
}

.loading {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  display:none;
  z-index:999999;
}

.loading > p {

    place-self: center center;
    margin-bottom: 10rem;
    color: white;
    font-size: x-large;
    text-align: center;
}

textarea {
  width: 100%;
}




li.active{
  display: block;
}

.processing {
  display: grid;
}

.loading-wheel {
  width: 20px;
  height: 20px;
  margin-top: -40px;
  margin-left: -40px;

  position: absolute;
  top: 50%;
  left: 50%;

  border-width: 30px;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}
.style-2 .loading-wheel {
  border-style: double;
  border-color: #ccc transparent;
}
@keyframes spin {
  0% {
      -webkit-transform: rotate(0);
  }
  100% {
      -webkit-transform: rotate(-360deg);
  }
}




.sidebar_tooltip {
  position: relative;
  display: inline-block;
}

.sidebar_tooltiptext{
  background-color: #002d45;
  border-radius: 0.25em;
  font-weight: bold;
  margin-right: 70px;
  margin-top: 0px;
  opacity: 0;
  overflow: visible;
  padding: 0em 1.5em;
  pointer-events: none;
  position: fixed;
  right: 0;
  text-align: center;
  transition: opacity 0.3s;
  vertical-align: middle;
  white-space: nowrap;
  /* width: fit-content; */
  z-index: 1;
}

.sidebar-main{
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap:1rem;
  overflow: scroll;
  padding: 0.25em;
}

.sidebar-footer {
  border-top: 1px solid gray;
  display: grid;
  place-items: center center;
}

.paddock-item{
  border-radius: var(--radius-2);
  box-shadow: var(--shadow-3);
  display: grid;
  grid-template-areas: " check name zoom"
  " check area zoom";
  grid-template-rows: 2rem 2rem;
  grid-template-columns: 0.125fr 1fr 1fr;
  min-width: 300px;
  gap: 0.5rem;
  padding: 1rem;
}


@media only screen and (min-width: 1200px) {


}

.sidebar{
  z-index: 1000;
}

.sidebar-header {
  position: sticky;
  top:0;
}
.paddock-item_name{
  grid-area: name;
  place-self: center start;
}

.paddock-item_area {
  grid-area: area;
  place-self: center start;
}

.paddock-item > input[type=checkbox]{
  place-self: center center;
  grid-area:check;
}




.paddock-item_zoom{
  grid-area: zoom;
}

.paddock-item_edit{
grid-area: edit;
}

.property-select{
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 0;
  padding: var(--size-2);
  width: var(--size-14);
  background-color: rgba(53,88,134,75%);
  opacity: 0.9;
  border-radius: 0 0 0.25em 0.25em;
  display: grid;
  grid-auto-flow: row;
  gap: 0.35rem;
  color:white;
  align-items: center;
  box-shadow: var(--shadow-2);
  display: grid;
}

.property-select > span {
  font-size: 0.85em;
  text-align: center;
}

.selected-property-table{
  margin-block: var(--size-2);
  display: grid;
  grid-auto-flow: row;
  gap: var(--size-2);
  max-width: calc(100% - 1rem);
}

.selected-property-table > textarea{
max-width: 80%;
padding: var(--size-2);
max-height: var(--size-8);
resize: none;
}


.report_modal{
  width: 70%;
}


.flex {
  display: flex;
  flex-direction: column;
  gap: 1rem;

}

.overflow-scroll {
  overflow: scroll;
}


.report-paddock-list{
  display: flex;
  flex-direction: row;
  gap: 1em;
  flex-wrap: wrap;
}


.reporting-target_container > * > h3 {
  margin: 0;
  color:var(--brand-color);

}

.tool-details{
  place-self: end;
}

.reporting-target_container{
  display: flex;
  flex-direction: column;
  gap: 1em;
}


